const ApiRoutes = {
  LOGIN: {
    service: "/auth",
    url: "/login",
    method: "POST",
    authenticate: false,
  },
  FORGOT_PASSWORD: {
    service: "/auth",
    url: "/forgot-password",
    method: "POST",
    authenticate: false,
  },
  CHANGE_PASSWORD: {
    service: "/auth",
    url: "/change-password",
    method: "PUT",
    authenticate: true,
  },
  RESET_PASSWORD: {
    service: "/auth",
    url: "/reset-password",
    method: "PUT",
    authenticate: true,
  },
  VERIFY_LINK: {
    service: "/auth",
    url: "/verify-link",
    method: "POST",
    authenticate: true,
  },
  ADMIN_INFO: {
    service: "/auth",
    url: "/me",
    method: "GET",
    authenticate: true,
  },
  EDIT_ADMIN: {
    service: "/auth",
    url: "",
    method: "PUT",
    authenticate: true,
  },
  ADD_USER: {
    service: "/user",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  USER_LIST: {
    service: "/user",
    url: "", 
    method: "GET", 
    authenticate: true,
  },
  USER_DETAILS: {
    service: "/user",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  USER_EDIT: {
    service: "/user",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  USER_DELETE: {
    service: "/auth",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  ATTRIBUTE_LIST: {
    service: "/attribute",
    url: "", 
    method: "GET", 
    authenticate: true,
  },
  ATTRIBUTE_DELETE: {
    service: "/attribute/",
    url: ":id",
    method: "DELETE",
    authenticate: true,
  },
  ATTRIBUTE_ADD: {
    service: "/attribute",
    url: "", 
    method: "POST", 
    authenticate: true,
  },
  ITEM_ADD: {
    service: "/item",
    url: "", 
    method: "POST", 
    authenticate: true,
  },
  ITEM_UPDATE: {
    service: "/item",
    url: ":id", 
    method: "PUT", 
    authenticate: true,
  },
  ITEM_LIST: {
    service: "/item",
    url: "", 
    method: "GET", 
    authenticate: true,
  },
  ITEM_STEP_DELETE: {
    service: "/item/",
    url: "/:_id/:_attributeId", 
    method: "DELETE", 
    authenticate: true,
  },
  ITEM_STEP_UPDATE: {
    service: "/item/",
    url: "/:_id/:_stepId", 
    method: "PUT", 
    authenticate: true,
  },
};

export default ApiRoutes;
