const AppRoutes = {
  HOME: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  ADMIN_PROFILE: "/profile",
  PAGE_NOT_FOUND: "/404",
  DASHBOARD: "/dashboard",
  ADD_USER: "/user/add",
  USER_LIST: "/users",
  USER_EDIT: "/user/edit/:id",
  USER_VIEW: "/user/view/:id",
  ADD_ATTRIBUTE: "/attribute/add-attribute",
  ATTRIBUTE_LIST: "/attribute/attribute-list",
  FRONT_PAGE: "/home-page",
  ADD_ITEM: "/configurator/add",
  ITEM_LIST:"/configurator"
};
export default AppRoutes;
