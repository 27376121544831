import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ScrollHandler from "./components/ScrollHandler";
import "./scss/style.scss";
import AppRoutes from "./config/AppRoutes";
import { getToken } from "./helpers";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const NormalLayout = React.lazy(() => import("./containers/NormalLayout"));
/**
 *
 */
const App = () => {
  return (
    <BrowserRouter>
      <ScrollHandler />
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path={AppRoutes.FRONT_PAGE}
            name="HomePage"
            render={(props) => <NormalLayout {...props} />}
          />
          <Route
            exact
            path={AppRoutes.FORGOT_PASSWORD}
            name="ForgotPassword"
            render={(props) => <NormalLayout {...props} />}
          />
          <Route
            exact
            path={AppRoutes.RESET_PASSWORD}
            name="ResetPassword"
            render={(props) => <NormalLayout {...props} />}
          />
          <Route
            exact
            path={AppRoutes.LOGIN}
            render={(props) =>
              !getToken() ? <NormalLayout {...props} /> : <Redirect to="/" />
            }
            name="Login"
          />
          <Route
            path={AppRoutes.Home}
            render={(props) =>
              getToken() ? <TheLayout {...props} /> : <Redirect to="/login" />
            }
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
