const getToken = () => {
  try {
    const userData = JSON.parse(localStorage.getItem("gmerpUserData"));
    const token = userData ? userData.token : null;
    return token;
  } catch (error) {
    return "";
  }
};

// const setCheck = (email, password) => {
//   try {
//     const obj = { email, password };
//     const data = JSON.stringify(obj);
//     localStorage.setItem("staybaselog", data);
//     return "";
//   } catch (error) {
//     return "";
//   }
// };
const getCheck = () => {
  try {
    const data = JSON.parse(localStorage.getItem("gmerpUserData"));
    return data;
  } catch (error) {
    return "";
  }
};
const clearCheck = () => {
  try {
    localStorage.removeItem("gmerpUserData");
    return "";
  } catch (error) {
    return "";
  }
};

const setUserData = async (data) => {
  try {
    const userData = JSON.stringify(data);
    localStorage.setItem("gmerpUserData", userData);
    return "";
  } catch (error) {
    return "";
  }
};

const isLogin = () => {
  try {
    const data = JSON.parse(localStorage.getItem("gmerpUserData"));
    if (data) {
      return true;
    }
    return false;
  } catch (error) {
    return "";
  }
};

const logout = () => {
  try {
    localStorage.removeItem("gmerpUserData");
    localStorage.removeItem("gmerpUserRoleData");
    window.location.href = "/login";
    return "";
  } catch (error) {
    return "";
  }
};

const setUserRoleData = async (data) => {
  try {
    const userRoleData = JSON.stringify(data);
    localStorage.setItem("gmerpUserRoleData", userRoleData);
    return "";
  } catch (error) {
    return "";
  }
};

const getCheckRoleData = () => {
  try {
    const data = JSON.parse(localStorage.getItem("gmerpUserRoleData"));
    return data;
  } catch (error) {
    return "";
  }
};

// eslint-disable-next-line
export default { getToken };
export {
  getToken,
  getCheck,
  clearCheck,
  setUserData,
  isLogin,
  logout,
  setUserRoleData,
  getCheckRoleData,
};
